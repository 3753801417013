import React, { useEffect, useState, useCallback } from 'react';
import { FaArrowRight, FaArrowLeft, FaArrowUp, FaArrowDown, FaPlus } from 'react-icons/fa';
import './Controls.css';
import { FaX } from 'react-icons/fa6';

const ControlItem = React.memo(({ icon, description, keys, alt }) => (
    <div className={alt ? "control-item-alt" : "control-item"}>
        <div className="controls">
            <p><strong>{keys.map((key, index) => (
                <span key={index}>{key} {index < keys.length - 1 && <FaPlus className="control-icon" />}</span>
            ))}</strong></p>
        </div>
        <div className="control-description">
            <p>{description}</p>
        </div>
    </div>
));

export default function Controls() {
    const [showControls, setShowControls] = useState(true);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleControls = useCallback(() => {
        setShowControls(prevShowControls => !prevShowControls);
    }, []);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey && event.key === 'h') {
                event.preventDefault();
                toggleControls();
                toggleModal();
            }
        };

        const handleResize = () => {
            setIsMobile(window.innerWidth < 1200);
        };

        document.addEventListener('keydown', handleKeyDown);
        window.addEventListener('resize', handleResize);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('resize', handleResize);
        };
    }, [toggleControls]);

    const toggleModal = () => {
        setIsModalOpen(prevIsModalOpen => !prevIsModalOpen);
    };

    return (
        <>
            {!isMobile && (
                <div className="controls-container">
                    {showControls ? (
                        <div className="controls-grid">
                            <ControlItem keys={['pgUp']} description="move left" />
                            <ControlItem keys={['pgDn']} description="move right" />
                            <ControlItem icon={<FaArrowRight />} description="next character" keys={[<FaArrowRight className="control-icon" />]} />
                            <ControlItem keys={['Ctrl', <FaArrowRight className="control-icon" />]} description="next word" />
                            <ControlItem keys={['Ctrl', 'Shift', <FaArrowRight className="control-icon" />]} description="whole suggestion" />
                            <ControlItem icon={<FaArrowLeft />} description="delete character" keys={[<FaArrowLeft className="control-icon" />]} />
                            <ControlItem icon={<FaArrowUp />} description="refetch suggestion" keys={[<FaArrowUp className="control-icon" />]} />
                            <ControlItem icon={<FaArrowDown />} description="clear suggestion" keys={[<FaArrowDown className="control-icon" />]} />
                            <ControlItem keys={['Ctrl', 'H']} description="hide controls" />
                        </div>
                    ) : (
                        <div className="controls-grid">
                            <ControlItem keys={['Ctrl', 'H']} description="controls Hint" />
                        </div>
                    )}
                </div>
            )}
            {showControls && !isMobile &&
                <div className="controls-container-alt">
                    <div className="controls-grid-alt">
                        <ControlItem alt keys={['Ctrl', 'S']} description="save text" />
                        <ControlItem alt keys={['Ctrl', 'Shift', 'S']} description="share text" />
                    </div>
                </div>
            }
            {isMobile && (
                <div className="mobile-controls" onClick={e=> e.isPropagationStopped()}>
                    <button onClick={toggleModal} className="mobile-controls-button">Show Controls</button>
                    {isModalOpen && (
                        <div className="modal">
                            <div className="modal-content">
                                <button onClick={toggleModal} className="close-modal"><FaX /></button>
                                <div className="modal-controls-list">
                                    <ControlItem keys={['pgUp']} description="move left" />
                                    <ControlItem keys={['pgDn']} description="move right" />
                                    <ControlItem icon={<FaArrowRight />} description="next character" keys={[<FaArrowRight className="control-icon" />]} />
                                    <ControlItem keys={['Ctrl', <FaArrowRight className="control-icon" />]} description="next word" />
                                    <ControlItem keys={['Ctrl', 'Shift', <FaArrowRight className="control-icon" />]} description="whole suggestion" />
                                    <ControlItem icon={<FaArrowLeft />} description="delete character" keys={[<FaArrowLeft className="control-icon" />]} />
                                    <ControlItem icon={<FaArrowUp />} description="refetch suggestion" keys={[<FaArrowUp className="control-icon" />]} />
                                    <ControlItem icon={<FaArrowDown />} description="clear suggestion" keys={[<FaArrowDown className="control-icon" />]} />
                                    <ControlItem alt keys={['Ctrl', 'S']} description="save text" />
                                    <ControlItem alt keys={['Ctrl', 'Shift', 'S']} description="share text" />
                                    <ControlItem keys={['Ctrl', 'H']} description="hide controls" />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}
