import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { debounce } from 'lodash';
import './App.css';
import Controls from './Components/Controls/Controls';
import Profile from './Components/Profile/Profile';
import SplashScreen from './Components/Loading/Loading';
import ControlsBar from './Components/Controls/ControlsBar';

const ITYPE = () => {
  const [text, setText] = useState('');
  const [title, setTitle] = useState('');
  const [currentSuggestion, setCurrentSuggestion] = useState('');
  const [fontSize, setFontSize] = useState(80);
  const contentRef = useRef(null);
  const controlBarRef = useRef(null);
  const titleRef = useRef(null);
  const containerRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 420);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 580) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener('resize', handleResize);

    // Initial check
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFadeOut(true);
      setTimeout(() => {
        setLoading(false);
      }, 500); // Match this timeout to the CSS transition duration
    }, 2000); // Simulate a 3-second loading time

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if ('virtualKeyboard' in navigator) {
      navigator.virtualKeyboard.overlaysContent = true;
      navigator.virtualKeyboard.hide();
    }
  }, []);

  useEffect(() => {
    const updateOverflow = () => {
      const container = containerRef.current;
      if (container.scrollHeight > container.clientHeight) {
        container.style.overflowY = 'auto';
      } else {
        container.style.overflowY = 'unset';
      }
    };

    setTimeout(() => {
      updateOverflow();
    }, 1000);
  }, [text]);

  // Debounced function to fetch suggestions
  const debouncedFetchSuggestions = useCallback(
    debounce(async (text) => {
      try {
        const response = await axios.post('/.netlify/functions/prediction', { text });
        const newSuggestion = response.data.response.candidates.replace(/[\n↵*]/g, '').trim() || '';
        setCurrentSuggestion(newSuggestion);
      } catch (error) {
        console.error('Error fetching suggestions from Netlify function:', error);
      }
    }, 800),
    []
  );

  const moveCursorToEnd = useCallback(() => {
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(contentRef.current);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
  }, []);

  const moveCursorOneStepLeft = useCallback(() => {
    const selection = window.getSelection();
    if (selection.rangeCount) {
      try {
        selection.modify("move", "backward", "character");
      } catch (error) {
        // Handle any unexpected errors gracefully
        console.error('Error moving cursor left:', error);
        const range = selection.getRangeAt(0);
        selection.removeAllRanges();
        range.setStart(range.startContainer, range.startOffset - 1);
        selection.addRange(range);
      }
    }
  }, []);

  const moveCursorOneStepRight = useCallback(() => {
    const selection = window.getSelection();
    if (selection.rangeCount) {
      try {
        selection.modify("move", "forward", "character");
      } catch (error) {
        // Handle any unexpected errors gracefully
        console.error('Error moving cursor right:', error);
        const range = selection.getRangeAt(0);
        selection.removeAllRanges();
        range.setStart(range.startContainer, range.startOffset + 1);
        selection.addRange(range);
      }
    }
  }, []);

  // Fetch suggestions when text changes
  useEffect(() => {
    if (text.length > 0) {
      debouncedFetchSuggestions(text);
    } else {
      setCurrentSuggestion('');
      contentRef.current.focus();
    }
  }, [text, debouncedFetchSuggestions]);

  // Calculate font size based on text length
  useEffect(() => {
    // if on mobile don't change font size
    if (window.innerWidth < 768) return;
    const calculateFontSize = () => {
      const length = Math.max(text.length, currentSuggestion.length);
      if (length < 60) return 70;
      if (length > 240) return 32;
      return 80 - ((length - 60) * (60 / 180));
    };
    setFontSize(Math.max(calculateFontSize(), 32));
  }, [text, currentSuggestion]);

  // Scroll to the bottom of the container
  useEffect(() => {
    const container = containerRef.current;
    container.scrollTop = container.scrollHeight;
  }, [text]);

  // Update titleRef with the title
  useEffect(() => {
    titleRef.current.innerText = title;
  }, [title]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && (event.key === 'c' || event.key === 'v' || event.key === 'x' || event.key === 'a')) {
        event.preventDefault();
      }

      // Prevent multiple spaces
      if (event.key === ' ' && text.endsWith(' ')) {
        event.preventDefault();
      }
      // Prevent multiple newlines
      if (event.key === 'Enter' && text.endsWith('\n')) {
        event.preventDefault();
      }

      // Temporarily prevent Enter key
      if (event.key === 'Enter') {
        event.preventDefault();
      }

      if (event.key === 'ArrowRight') {
        event.preventDefault();

        setText((prevText) => {
          let newText = prevText; // Start with the current text
          if (event.ctrlKey) {
            if (event.shiftKey) {
              newText += currentSuggestion.slice(prevText.length); // Accept entire suggestion
              setCurrentSuggestion('');
            } else {
              const nextWord = currentSuggestion.slice(text.length).split(' ')[0];
              newText += nextWord + ' '; // Accept next word
            }
          } else {
            const nextChar = currentSuggestion.charAt(text.length);
            newText += nextChar; // Accept next character
          }

          // Update the DOM directly within the state update
          contentRef.current.innerText = newText;

          // Set the cursor to the end
          setTimeout(() => {
            if (contentRef.current) {
              moveCursorToEnd();
            }
          }, 0);

          return newText; // Return the updated text for the state
        });
      }

      if (event.key === 'ArrowLeft') {
        event.preventDefault();

        setText((prevText) => {
          const newText = prevText.slice(0, -1); // Calculate the new text here
          contentRef.current.innerText = newText; // Immediately update the DOM

          // Use `newText` to set the cursor position
          setTimeout(() => {
            if (contentRef.current) {
              const selection = window.getSelection();
              const range = document.createRange();
              range.setStart(contentRef.current.childNodes[0], newText.length); // Set the cursor to the correct position
              range.collapse(true);
              selection.removeAllRanges();
              selection.addRange(range);
            }
          }, 0);

          return newText; // Return the updated text for the state update
        });
      }

      if (event.key === 'ArrowUp') {
        event.preventDefault();
        // Refetch new suggestion
        debouncedFetchSuggestions(text);
      }

      if (event.key === 'ArrowDown') {
        event.preventDefault();
        // Clear suggestions
        setCurrentSuggestion('');
      }

      if (event.ctrlKey && event.key === 's') {
        event.preventDefault();
        // Generate title and save the text
        generateTitleAndSave();
      }

      if (event.ctrlKey && event.shiftKey && event.key === 'S') {
        event.preventDefault();
        // Share the text with web share API
        if (navigator.share) {
          navigator.share({
            title: 'Written with ITYPE',
            text: text,
          });
        }
      }

      // Move cursor with page up and page down keys
      if (event.key === 'PageUp' || event.key === 'PageDown') {
        if (event.key === 'PageUp') {
          moveCursorOneStepLeft();
          event.preventDefault();
        } else {
          moveCursorOneStepRight();
          event.preventDefault();
        }
      }
    };

    const handleDragStart = (event) => {
      event.preventDefault();
    };

    const currentContentRef = contentRef.current;
    window.addEventListener('keydown', handleKeyDown);
    currentContentRef.addEventListener('dragstart', handleDragStart);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      currentContentRef.removeEventListener('dragstart', handleDragStart);
    };
  }, [text, currentSuggestion, debouncedFetchSuggestions, moveCursorToEnd, moveCursorOneStepLeft, moveCursorOneStepRight]);

  const handleInput = (input) => {
    setText(input);
    if (contentRef.current) {
      contentRef.current.innerText = input;
      moveCursorToEnd();
    }
  };

  const handleAcceptChar = () => {
    const nextChar = currentSuggestion.charAt(text.length);
    const newText = text + nextChar;
    setText(newText);
    handleInput(newText);
  };
  
  const handleAcceptWord = () => {
    const nextWord = currentSuggestion.slice(text.length).split(' ')[0] + ' ';
    const newText = text + nextWord;
    setText(newText);
    handleInput(newText);
  };
  
  const handleAcceptSuggestion = () => {
    const newText = text + currentSuggestion.slice(text.length);
    setText(newText);
    setCurrentSuggestion('');
    handleInput(newText);
  };
  
  const handleEditableInput = (event) => {
    let inputText = event.target.innerText;

    // Replace multiple newlines with a single newline
    inputText = inputText.replace(/\n{2,}/g, '\n');

    setText(inputText);
  };

  const generateTitleAndSave = async () => {
    try {
      const titleResponse = await axios.post('/.netlify/functions/generate-title', { text });
      const title = titleResponse.data.title;
      setTitle(title);

      const reformatResponse = await axios.post('/.netlify/functions/reformat-text', { title, text });
      const formattedText = reformatResponse.data.formattedText;

      // Save the text using the browser's save dialog
      const blob = new Blob([formattedText], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${title}.txt`
      a.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error generating title and saving the text:', error);
    }
  };

  const getSuggestedText = () => {
    return currentSuggestion.slice(text.length);
  };

  return (
    <>
      {loading && <SplashScreen fadeOut={fadeOut} />}
      <h1 className='logo'>I<span style={{ fontWeight: "100" }}>|</span><span style={{ opacity: "0.3" }}>TYPE</span></h1>
      <h1 className='title' ref={titleRef}>{title}</h1>
      <Profile src='https://avatars.githubusercontent.com/u/58892151?v=1' alt='profile' />
      <div className="typewriter-restriction">
        <div className="typewriter-container" style={{ fontSize: `${fontSize}px` }} ref={containerRef}>
          <div className="typewriter-wrapper">
            <div className="typewriter-input-wrapper">
              <div className="typewriter-suggestion">
                {text + getSuggestedText()}
              </div>
              <div
                virtualKeyboardPolicy="manual"
                ref={contentRef}
                contentEditable
                onInput={handleEditableInput}
                onFocus={(e) => isMobile ? e.target.blur() : e.target.focus()}
                className="typewriter-input"
                style={{ fontSize: `${fontSize}px` }}
              />
            </div>
          </div>
        </div>
      </div>
      <Controls />
      <ControlsBar
        onChange={handleInput}
        currentText={text} 
        ref={controlBarRef}
        onSave={generateTitleAndSave}
        onShare={() => {
          if (navigator.share) {
            navigator.share({
              title: 'Written with ITYPE',
              text: text,
            });
          }
        }}
        onAcceptChar={handleAcceptChar}
        onAcceptWord={handleAcceptWord}
        onAcceptSuggestion={handleAcceptSuggestion}
        onDelete={() => {
          setText((prevText) => {
            const newText = prevText.slice(0, -1);
            contentRef.current.innerText = newText;
            moveCursorToEnd();
            return newText;
          });
        }}
        onRefetch={() => {
          debouncedFetchSuggestions(text);
        }}
        onDiscardSuggestion={() => {
          setCurrentSuggestion('');
        }}
      />
    </>
  );
}

export default ITYPE;


