import React from 'react';
import './Loading.css';

const SplashScreen = ({fadeOut}) => {
    return (
        <div className={`splash-screen ${fadeOut ? 'fade-out' : ''}`}>
            <h1 className='i'>I</h1>
            <div className="blinking-cursor"></div>
            <h1 className='type'>TYPE</h1>
        </div>
    );
};

export default SplashScreen;
