import React, { forwardRef, useState } from 'react';
import './ControlsBar.css';
import { FaRedo, FaSave, FaShare } from 'react-icons/fa';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

const ControlsBar = forwardRef(({ onSave, onShare, onAcceptChar, onAcceptWord, onAcceptSuggestion, onRefetch, onChange, currentText}, ref) => {
  const [layoutName, setLayoutName] = useState("default");

  const handleShift = () => {
    setLayoutName((prevLayoutName) => prevLayoutName === "default" ? "shift" : "default");
  };

  const handleNumbers = () => {
    setLayoutName((prevLayoutName) => prevLayoutName !== "numbers" ? "numbers" : "default");
  };

  const handleSymbols = () => {
    setLayoutName((prevLayoutName) => prevLayoutName !== "symbols" ? "symbols" : "default");
  };

  const handleABC = () => {
    setLayoutName("default");
  };

  const onKeyPress = (button) => {
    // if can vibrate, vibrate
    if (navigator.vibrate) {
      navigator.vibrate(20);
    }
  
    if (button === "{shift}" || button === "{capslock}") handleShift();
    if (button === "{numbers}") handleNumbers();
    if (button === "{symbols}") handleSymbols();
    if (button === "{abc}") handleABC();
    if (button === "{backspace}") {
      onChange(currentText.slice(0, -1));
    } else if (button === "{space}") {
      onChange(currentText + " ");
    } else if (button !== "{shift}" && button !== "{capslock}" && button !== "{numbers}" && button !== "{abc}" && button !== "{symbols}" && button !== "{backspace}" && button !== "{space}" && button !== "{enter}") {
      onChange(currentText + button);
    }
  };
  

  return (
    <div className="control-bar" ref={ref}>
    <button onClick={onAcceptChar}>CHARACTER</button>
    <button onClick={onAcceptWord}>WORD</button>
    <button onClick={onAcceptSuggestion}>SUGGESTION</button>
    <button onClick={onRefetch}><FaRedo /></button>
    <button onClick={onSave}><FaSave /></button>
    <button onClick={onShare}><FaShare /></button>
    <hr />
    <Keyboard
      onKeyPress={onKeyPress}
      mergeDisplay={true}
      layoutName={layoutName}
      layout={{
        default: [
          "` 1 2 3 4 5 6 7 8 9 0 - = {backspace}",
          "q w e r t y u i o p",
          "a s d f g h j k l",
          "{shift} z x c v b n m , .",
          "{numbers} {space} {symbols} {backspace}"
        ],
        shift: [
          "~ ! @ # $ % ^ & * ( ) _ + {backspace}",
          "Q W E R T Y U I O P",
          "A S D F G H J K L",
          "{shift} Z X C V B N M ! ?",
          "{numbers} {space} {symbols} {backspace}"
        ],
        numbers: [
          "1 2 3",
          "4 5 6",
          "7 8 9",
          "{abc} {space} {backspace}"
        ],
        symbols: [
          "! @ # $ % ^ & * ( )",
          "` ~ - _ = + { [ ] } \\ |",
          ": ; ' \" , < . > / ?",
          "{abc} {space} {backspace}"
        ]
      }}
      display={{
        "{numbers}": "123",
        "{ent}": "return",
        "{escape}": "esc ⎋",
        "{tab}": "tab ⇥",
        "{backspace}": "⌫",
        "{capslock}": "caps lock ⇪",
        "{shift}": "⇧",
        "{controlleft}": "ctrl ⌃",
        "{controlright}": "ctrl ⌃",
        "{altleft}": "alt ⌥",
        "{altright}": "alt ⌥",
        "{metaleft}": "cmd ⌘",
        "{metaright}": "cmd ⌘",
        "{abc}": "ABC",
        "{space}": "space",
        "{enter}": "↵",
        "{symbols}": "#+="
      }}
    />
  </div>

  );
});

export default ControlsBar;
